import { programs } from '@metaplex/js'
import { PublicKey } from '@solana/web3.js'

// @ts-ignore
programs.auction.AuctionProgram.PUBKEY = new PublicKey("Fjj7b2c2iYynGrAAQ9ByVAXbEDTSii5JxnBJuD5ND65v");
// @ts-ignore
programs.metaplex.MetaplexProgram.PUBKEY = new PublicKey("EGHhdhhZj3qmURtXAt1tMj7iGYrjQhoaVBMgTGGiU538");
// @ts-ignore
programs.metadata.MetadataProgram.PUBKEY = new PublicKey("Dn5PnjnVM6oYMTbDzrbJxPUJygA28XEZHfuT53yjvbQV");
// @ts-ignore
programs.vault.VaultProgram.PUBKEY = new PublicKey("FGvTDUsiTbNg51RCc2BYgZnn5SgEC7CXRvdG6zXJGsqf")
